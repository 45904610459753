<template>
  <div>2</div>
</template>

<script>
export default {
  name: 'Step2',
}
</script>

<style></style>
