<template>
  <div></div>
</template>

<script>
export default {
  name: 'Portfolio',

  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style></style>
