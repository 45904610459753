<template>
  <div class="block-blog">
    <div class="block-blog__post" v-for="post in getPosts()" :key="post.id">
      <UICard :background="'white'" :padding="5" :borderRadius="20">
        <UIPost :post="post"></UIPost>
      </UICard>
    </div>
  </div>
</template>

<script>
import UICard from '@/components/ui/card/card.vue'
import UIPost from '@/components/ui/post/post.vue'

export default {
  name: 'BlockBlog',

  components: {
    UICard,
    UIPost,
  },

  methods: {
    getPosts() {
      return [
        {
          id: 0,
          img: require('@/assets/img/posts/tg.jpg'),
          text: [
            'В преддверии выборов в США криптовалютный рынок столкнулся с крупным оттоком из ETF на биткойн, вызванным ожиданием высокой волатильности.',
            'На фоне предвыборной гонки, в которой Трамп открыто поддерживает криптовалюты, а Харрис обещает поддерживать регуляции, инвесторы готовятся к возможным изменениям.',
            'Wincent отметил, что значительные перемены в политике вокруг криптовалют возможны лишь после выборов. На фоне этих событий биткойн подорожал на 2,3%, достигнув $68 621, и за год прибавил более 60%, что делает его конкурентоспособным на фоне других активов.',
          ],
          data: '2024-11-6',
          tags: ['Биткоин', 'криптовалюты', 'ETF'],
          view: 'left',
        },
        {
          id: 1,
          img: require('@/assets/img/posts/tg.jpg'),
          text: [
            'В преддверии выборов в США криптовалютный рынок столкнулся с крупным оттоком из ETF на биткойн, вызванным ожиданием высокой волатильности.',
            'На фоне предвыборной гонки, в которой Трамп открыто поддерживает криптовалюты, а Харрис обещает поддерживать регуляции, инвесторы готовятся к возможным изменениям.',
            'Wincent отметил, что значительные перемены в политике вокруг криптовалют возможны лишь после выборов. На фоне этих событий биткойн подорожал на 2,3%, достигнув $68 621, и за год прибавил более 60%, что делает его конкурентоспособным на фоне других активов.',
          ],
          data: '2024-11-6',
          tags: ['Биткоин', 'криптовалюты', 'ETF'],
          view: 'right',
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.block-blog {
  position: relative;
  display: grid;
  z-index: 10;
  grid-template-columns: 1fr;
  padding: 10%;
  gap: 40px;
}
</style>
