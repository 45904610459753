<template>
  <div>1</div>
</template>

<script>
export default {
  name: 'Step1',
}
</script>

<style></style>
