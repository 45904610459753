<template>
  <div>5</div>
</template>

<script>
export default {
  name: 'Step5',
}
</script>

<style></style>
