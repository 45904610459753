<template>
  <div>3</div>
</template>

<script>
export default {
  name: 'Step3',
}
</script>

<style></style>
