<template>
  <div class="services-content">
    <BlockConstructor />
  </div>
</template>

<script>
import BlockConstructor from '@/components/block/constructor/constructor.vue'
export default {
  name: 'ServicesContent',
  components: {
    BlockConstructor,
  },
}
</script>

<style></style>
